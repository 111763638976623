import {WorksCategory} from '@logic/models/Works/WorksCategory';
import {WorksResponse} from '@logic/service/ApiRequest/ResponseTypes/Works/WorksResponse';

import IDataSliceType from '@store/reducers/IDataSliceType';

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState: IDataSliceType<WorksCategory[]> = {
    data: [],
    loaded: false
};

export const WorksSlice = createSlice({
    name: `worksSlice`,
    initialState,
    reducers: {
        setWorks: (state, action : PayloadAction<WorksResponse>) => {
            state.data = action.payload;
            state.loaded = true;
        }
    },
});

export const {setWorks} = WorksSlice.actions;
export default WorksSlice.reducer;
