import {UserRole} from '@logic/models/Auth/UserRole';
import UserNew from '@logic/models/User/UserNew';
import TokenResponse from '@logic/service/ApiRequest/ResponseTypes/Auth/TokenResponse';
import {Tokens} from '@logic/service/ApiRequest/ResponseTypes/Token/TokenResponse';
import UserResponse, {AuthResponse} from '@logic/service/ApiRequest/ResponseTypes/User/UserResponse';

import IDataSliceType from '@store/reducers/IDataSliceType';

import {createSlice, PayloadAction} from '@reduxjs/toolkit';


const initialState : IDataSliceType<UserNew> = {
    data: {
        'id': -1,
        'firstName': '',
        'lastName': '',
        'patronymic': '',
        'phone': '',
        'email': '',
        'country': '',
        'authorities': [],
        'role': UserRole.NotSelected
    },
    loaded: false
};

export const userNewSlice = createSlice({
    name: `userSlice`,
    initialState,
    reducers: {
        setUser: (state, action : PayloadAction<UserResponse>) => {
            state.data = action.payload;
            state.loaded = true;
        },
        setToken: (state, action : PayloadAction<TokenResponse>) => {
            localStorage.setItem(Tokens.ACCESS_TOKEN, action.payload.accessToken);
            localStorage.setItem(Tokens.REFRESH_TOKEN, action.payload.refreshToken);
        },
        setAuthSession: (state, action : PayloadAction<AuthResponse>) => {
            state.data = action.payload.user;
            state.loaded = true;
            localStorage.setItem(Tokens.ACCESS_TOKEN, action.payload.tokenPair.accessToken);
            localStorage.setItem(Tokens.REFRESH_TOKEN, action.payload.tokenPair.refreshToken);
        }
    },
});

export const {setUser, setAuthSession, setToken} = userNewSlice.actions;
export default userNewSlice.reducer;
