import RuleMessage from "@logic/forms/validators/rules/RuleMessage";
import ApiRequest from "@logic/service/ApiRequest/ApiRequest";

import { PayAssistantError } from "../API/error/PayAssistantError";


export default abstract class AbstractApiService {

    protected apiRequest : ApiRequest;
    protected title : RuleMessage | null = null;
    protected constructor (setError: (error: PayAssistantError) => void) {
        this.apiRequest = new ApiRequest(setError);
    }


    public setTitle (title: RuleMessage) {
        this.title = title;
        return this;
    }
}