import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import ApiResponse from '@logic/service/ApiRequest/ApiResponse';
import ApiService from '@logic/service/ApiService/ApiService';
import {usePaApiService} from "@hooks/usePaApiService";

import {useAppSelector} from '@store/hooks/hooks';
import {setPhoneCodes} from '@store/reducers/phoneCode/phoneCodeSlice';

type PropsType = {
    onLoadingError?: (response: ApiResponse<any>) => void;
};

export default function PhoneCodesLoader ({onLoadingError}: PropsType)
{
    const dispatch = useDispatch();
    const phones = useAppSelector((state) => state.countriesSlice);

    const apiService = usePaApiService(ApiService);

    useEffect(() => {
        if(phones.loaded) return;
        apiService.getPhoneCodes().then(response => {
            if(!response.isSuccess()) {
                if(onLoadingError) onLoadingError(response);
                return;
            }
            dispatch(setPhoneCodes(response.getData()));
        });
    }, [phones]);

    return <></>;
}