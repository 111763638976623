import { createSlice } from '@reduxjs/toolkit';

interface chatState {
    rooms: {
        [key: string]: {
            newMessage: boolean;
            image: string;
            name: string;
            pining: boolean;
            participants: Array<number>;
            messages: Array<any>;
        };
    };
}

const initialState: chatState = {
    rooms: {
        0: {
            newMessage: true,
            image: ``,
            name: `Вёрстка сайта на WordPress и продвижение его в Google`,
            pining: true,
            participants: [1, 2],
            messages: [
                {
                    participant: 1,
                    message:
            `Повседневная практика показывает, что укрепление и развитие структуры представляет собой интересный эксперимент проверки соответствующий условий активизации!`,
                    time: `17:02`,
                },
                {
                    participant: 2,
                    message: `Соответствующий условий активизации`,
                    time: `17:02`,
                },
                {
                    participant: 2,
                    message: `Я с вами все же не соглашусь)`,
                    time: `17:02`,
                },
                {
                    participant: 3,
                    message: `Можно и так сказать, но я думаю, что всё будет хорошо!\n`,
                    time: `18:02`,
                },
            ],
        },
        1: {
            newMessage: true,
            image: ``,
            name: `Жабки`,
            pining: true,
            participants: [1, 2, 3],
            messages: [],
        },
    },
};

export const chatSlice = createSlice({
    name: `tasks`,
    initialState,
    reducers: {
        send (state, action) {
            state.rooms[action.payload.idRoom].messages.push({
                participant: action.payload.participant,
                message: action.payload.message,
                time: `18:00`,
            });
        },
    },
});

export default chatSlice.reducer;
