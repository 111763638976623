import RuleMessage, { ErrorsResponse} from '@logic/forms/validators/rules/RuleMessage';
import Helper from "@logic/helpers/Helper/Helper";
import {ApiMethods} from '@logic/service/ApiRequest/ApiMethods';
import ApiResponse from '@logic/service/ApiRequest/ApiResponse';
import TokenRefreshRequest from '@logic/service/ApiRequest/RequestTypes/Auth/TokenRefreshRequest';
import TokenResponse from '@logic/service/ApiRequest/ResponseTypes/Auth/TokenResponse';
import {Tokens} from '@logic/service/ApiRequest/ResponseTypes/Token/TokenResponse';
import Request from '@logic/service/ApiService/Request/Request';

import {URL} from '@store/urls';

import * as Sentry from '@sentry/nextjs';
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';

import { ApiErrorAdapter } from '../API/error/errorsAdapter';
import { PayAssistantError } from '../API/error/PayAssistantError';

type GetParams = Record<string, string> | null;

enum ApiMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
}

export default class ApiRequest {
    static updateTokens = false;

    private axiosApi = axios.create({
        baseURL: URL,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
    });
    private readonly setError: (error: PayAssistantError) => void;


    constructor (setError: (error: PayAssistantError) => void) {
        this.setError = setError;
        this.axiosApi.interceptors.request.use((config) => config);
        this.axiosApi.interceptors.response.use(
            response => response,
            (error: AxiosError<ErrorsResponse>) => {

                const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };
                const hasToken = Helper.hasAuthTokens();

                if (error?.response?.status === 401 && hasToken && !originalRequest._retry && !ApiRequest.updateTokens) {
                    ApiRequest.updateTokens = true;
                    originalRequest._retry = true;
                    return this.refreshToken().then((response) => {
                        localStorage.setItem(Tokens.REFRESH_TOKEN, response.data.refreshToken);
                        localStorage.setItem(Tokens.ACCESS_TOKEN, response.data.accessToken);
                        originalRequest!.headers!['Authorization'] = `Bearer ${response.data.accessToken}`;
                        ApiRequest.updateTokens = false;
                        return this.axiosApi(originalRequest);
                    });
                }

                const isRefreshTokenRequest = originalRequest?.url === ApiMethods.RefreshToken;

                if(isRefreshTokenRequest) {
                    localStorage.removeItem(Tokens.REFRESH_TOKEN);
                    localStorage.removeItem(Tokens.ACCESS_TOKEN);
                    return window.location.href = '/sign-in';
                }

                Sentry.captureException(error);
                return Promise.reject(error);
            },
        );
    }

    private async sendGetRequest<ResponseT> (config: AxiosRequestConfig): Promise<AxiosResponse<ResponseT>> {
        return await this.axiosApi.request({
            method: ApiMethod.GET,
            params:config,
            ...config
        });
    }


    private async sendPathRequest<ResponseT> (config: AxiosRequestConfig): Promise<AxiosResponse<ResponseT>> {
        return await this.axiosApi.request({
            method: ApiMethod.PATCH,
            ...config
        });
    }

    private async sendDeleteRequest<ResponseT> (config: AxiosRequestConfig): Promise<AxiosResponse<ResponseT>> {
        return await this.axiosApi.request({
            method: ApiMethod.DELETE,
            ...config
        });
    }

    private async sendPostRequest<ResponseT> (config: AxiosRequestConfig): Promise<AxiosResponse<ResponseT>> {
        return await this.axiosApi.request({
            method: ApiMethod.POST,
            ...config
        });
    }

    private async sendPutRequest<ResponseT> (config: AxiosRequestConfig): Promise<AxiosResponse<ResponseT>> {
        return await this.axiosApi.request({
            method: ApiMethod.PUT,
            ...config
        });
    }


    public async sendPost<ResponseT> (request: Request<any, any>): Promise<ApiResponse<ResponseT>> {
        const callback = () => this.sendPostRequest<ResponseT>(request.getConfig());
        return this.sendRequest(callback, request);
    }


    public async sendPut<ResponseT> (request: Request<any, any>): Promise<ApiResponse<ResponseT>> {
        const callback = () => this.sendPutRequest<ResponseT>(request.getConfig());
        return this.sendRequest(callback, request);
    }


    public async sendGet<ResponseT> (request: Request<any, any>, title?: RuleMessage | null): Promise<ApiResponse<ResponseT>> {
        const callback = () => this.sendGetRequest<ResponseT>(request.getConfig());
        return this.sendRequest(callback, request, title);
    }

    public async sendPath<ResponseT> (request: Request<any, any>): Promise<ApiResponse<ResponseT>> {
        const callback = () => this.sendPathRequest<ResponseT>(request.getConfig());
        return this.sendRequest(callback, request);
    }

    public async sendDelete<ResponseT> (request: Request<any, any>): Promise<ApiResponse<ResponseT>> {
        const callback = () => this.sendDeleteRequest<ResponseT>(request.getConfig());
        return this.sendRequest(callback, request);
    }


    private async sendRequest<ResponseT> (callback: () => Promise<AxiosResponse<ResponseT>>, request: Request<any, any>, title?: RuleMessage | null): Promise<ApiResponse<ResponseT>> {
        try {
            const response = await callback();
            return new ApiResponse<ResponseT>(response);
        } catch (error: any | AxiosError<{ message: RuleMessage }>) {
            this.setError(ApiErrorAdapter.getPayAssistantError(error.response!, title!));
            return new ApiResponse<ResponseT>(error.response);
        }
    }


    private async refreshToken (): Promise<AxiosResponse<TokenResponse>> {
        const body = {
            refreshToken: localStorage.getItem(Tokens.REFRESH_TOKEN)!,
            accessToken: localStorage.getItem(Tokens.ACCESS_TOKEN)!
        };
        const request = new Request<TokenRefreshRequest>({
            url: ApiMethods.RefreshToken,
            body
        });

        return await this.axiosApi.request({
            method: ApiMethod.POST,
            ...request.getConfig()
        });
    }

}