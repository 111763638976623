import Account from '@logic/models/Account/Account';
import {Contract} from '@logic/models/CompanyContract/Contract';
import Freelancer from '@logic/models/Freelancer/Freelancer';
import {SearchTaskFreelancers} from '@logic/models/Freelancer/TaskFreelancer';
import PagedData from '@logic/models/Table/PagedData';
import {ApiMethods} from '@logic/service/ApiRequest/ApiMethods';
import {CompaniesResponse, CompanyData} from '@logic/service/ApiRequest/ResponseTypes/Company/CompanyResponse';
import FindCompanyFreelancersResponse
    from '@logic/service/ApiRequest/ResponseTypes/CompanyFreelancers/FindCompanyFreelancersResponse';
import {FeesResponse} from '@logic/service/ApiRequest/ResponseTypes/FeesResponse/FeesResponse';

import {API} from '../baseAPI/API';

export const companyAPI = API.injectEndpoints({
    endpoints: (builder) => ({
        getContractByCompanyId: builder.query<Contract, number>({
            query: (id) => ApiMethods.CompanyContract.replace('{0}', id.toString()),
            providesTags: ['CompanyAccount']
        }),
        getAccountByCompanyId: builder.query<Account, number>({
            query: (id) => ApiMethods.GetCompanyAccount.replace('{0}', id.toString())
        }),
        getCompanies: builder.query<CompanyData, void>({
            query: () => ApiMethods.Companies,
            providesTags: ['Company'],
            transformResponse: (response: CompaniesResponse) => {
                if(Array.isArray(response)) {
                    return {
                        selectedCompany: response[0] ?? null,
                        allCompanies: response,
                        companyNeedOnBoarding: response.length === 0
                    };
                } 
                return response;
            },
        }),
        getFees: builder.query<FeesResponse, number>({
            query: (id) => ApiMethods.Fees.replace('{0}', id.toString())
        }),
        searchFreelancers: builder.query<SearchTaskFreelancers, {companyId: number, value: string}>({
            query: ({companyId, value}) => ({
                url: ApiMethods.SearchFreelancers.replace('{0}', companyId.toString()),
                params: {value},
                method: 'GET'
            })
        }),
        getCompanyFreelancers: builder.query<PagedData<Freelancer>, { id: number, pageSize: number, pageNumber: number}>({
            query: ({id, pageNumber, pageSize}) => ({
                url: ApiMethods.FindCompaniesFreelancers.replace('{0}', id.toString()),
                params: {pageNumber, pageSize},
                method: 'GET'
            }),
            providesTags: ['CompanyFreelancers'],
            transformResponse: (response: FindCompanyFreelancersResponse) => ({
                page: response?.page ?? 1,
                maxPage: response?.totalPages ?? 1,
                data: response?.companyFreelancers ?? []
            }),
        }),
        getCompanyFreelancersCount: builder.query<number, number>({
            query: (id) => ApiMethods.CompanyFreelancersQuantity.replace('{0}', id.toString()),
        })
    })
});

export const {
    useGetCompanyFreelancersCountQuery,
    useGetCompaniesQuery,
    useGetAccountByCompanyIdQuery,
    useGetContractByCompanyIdQuery,
    useGetFeesQuery,
    useSearchFreelancersQuery,
    useGetCompanyFreelancersQuery,
    useLazySearchFreelancersQuery} = companyAPI;