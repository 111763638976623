import {
    CountryCode,
    CountryCodes
} from '@logic/service/ApiRequest/ResponseTypes/Countries/PhoneCodesResponse';

import IDataSliceType from '@store/reducers/IDataSliceType';

import {createSlice, PayloadAction} from '@reduxjs/toolkit';


const initialState: IDataSliceType<CountryCode[]> = {
    data: [],
    loaded: false
};

export const CountryCodesSlice = createSlice({
    name: `countriesSlice`,
    initialState,
    reducers: {
        setCountryCodes: (state, action : PayloadAction<CountryCodes>) => {
            state.loaded = true;
            state.data = action.payload;
        },
    },

});

export const {setCountryCodes} = CountryCodesSlice.actions;
export default CountryCodesSlice.reducer;
