import {FreelancerSettings} from '@logic/models/Settings/Settings';
import FreelancerSettingsResponse
    from '@logic/service/ApiRequest/ResponseTypes/SettingsResponse/FreelancerSettingsResponse';

import IDataSliceType from '@store/reducers/IDataSliceType';

import {createSlice, PayloadAction} from '@reduxjs/toolkit';


const initialState: IDataSliceType<FreelancerSettings> = {
    loaded: false,
    data: null
};

export const freelancerSettingsSlice = createSlice({
    name: `freelancer settings`,
    initialState,
    reducers: {
        setFreelancerSettings: (state, action : PayloadAction<FreelancerSettingsResponse>) => {
            state.data = action.payload;
            state.loaded = true;
        }
    },

});

export const {setFreelancerSettings} = freelancerSettingsSlice.actions;
export default freelancerSettingsSlice.reducer;
