import React from 'react';

import SiteType from '@logic/enums/TypeSite';
import RuleMessage from "@logic/forms/validators/rules/RuleMessage";
import Helper from '@logic/helpers/Helper/Helper';
import LanguagesType from '@logic/language/types/LanguagesType/LanguagesType';
import GeneralPopupError from '@logic/models/GeneralPopupError/GeneralPopupError';
import { PayAssistantError } from '@logic/service/API/error/PayAssistantError';

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type GeneralStates = {
    loverPanelIsCloseAbsolute: boolean;
    language: LanguagesType;
    availableLanguages: Array<LanguagesType>,
    defaultCounty: string,
    loading: boolean,
    ip: string,
    host: string,
    site: SiteType,
    menuIsOpen: boolean,
    routIsLoading: boolean,
    errors: Array<GeneralPopupError>,

    modalWindows: Array<{
        id: number,
        component: React.FC,
    }>,

};

const getLang = () => {
    if (process.env.NEXT_PUBLIC_SITE === 'ru') {
        return LanguagesType.RU;
    }
    if (process.env.NEXT_PUBLIC_SITE === 'com') {
        return LanguagesType.EN;
    }
    return LanguagesType.UZ;
};

const getDefaultCountry = () => {
    if (process.env.NEXT_PUBLIC_SITE === 'ru') {
        return 'RU';
    }
    if (process.env.NEXT_PUBLIC_SITE === 'com') {
        return 'US';
    }
    return 'UZ';
};

const getAvailableLanguages = () => {
    if (process.env.NEXT_PUBLIC_SITE === 'ru') {
        return [LanguagesType.RU, LanguagesType.EN];
    }
    if (process.env.NEXT_PUBLIC_SITE === 'com') {
        return [LanguagesType.RU, LanguagesType.EN];
    }
    return [LanguagesType.RU, LanguagesType.EN, LanguagesType.UZ];
};

const initialState: GeneralStates = {
    loverPanelIsCloseAbsolute: false,
    language: getLang() as LanguagesType,
    defaultCounty: getDefaultCountry(),
    availableLanguages: getAvailableLanguages(),
    loading: false,
    site: process.env.NEXT_PUBLIC_SITE as SiteType,
    menuIsOpen: false,
    routIsLoading: false,
    ip: '',
    host: '',
    errors: [],
    modalWindows: [],
};

export const generalStatesSlice = createSlice({
    name: `generalStates`,
    initialState,
    reducers: {
        setLoverPanelIsCloseAbsolute (state, action: PayloadAction<boolean>) {
            state.loverPanelIsCloseAbsolute = action.payload;
        },
        setRoutIsLoading (state) {
            state.routIsLoading = true;
        },
        setRoutLoadComplete (state) {
            state.routIsLoading = false;
        },
        setRoutIsNotLoadingCauseError (state) {
            state.routIsLoading = false;
        },
        setLang (state, action) {
            Helper.setCookie(`lang`, action.payload as LanguagesType);
            state.language = action.payload as LanguagesType;
        },
        turnLoading: (state) => {
            state.loading = true;
        },
        offLoading: (state) => {
            state.loading = false;
        },
        turnMenu: (state) => {
            state.menuIsOpen = true;
        },
        offMenu: (state) => {
            state.menuIsOpen = false;
        },
        setIpAndHostAndSiteAndDefaultLang: (state, action: PayloadAction<{ip: string, host: string, site: SiteType}>) => {
            state.ip = action.payload.ip;
            state.host = action.payload.host;
            state.site = action.payload.site;
        },

        addErrors: (state, action: PayloadAction<{ error: PayAssistantError, ignoreFields?: Array<string>, title?: RuleMessage }>) => {
            const ignoreFields = action.payload.ignoreFields || [];
            const errors = action.payload.error.getPreparedErrors(action.payload.title, ignoreFields);
            state.errors = [...state.errors, ...errors];
        },
        removeError: (state, action: PayloadAction<number>) => {
            state.errors = state.errors.filter(er => er.id !== action.payload);
            // state.errors.push(action.payload);
        },
        openModalWindow: (state, action: PayloadAction<{id: number, component: React.FC}>) => {
            state.modalWindows.push(action.payload);
        },
        removeModalWindow: (state, action: PayloadAction<number>) => {
            state.modalWindows = state.modalWindows.filter(er => er.id !== action.payload);
        }
    },
});

export const {
    setLoverPanelIsCloseAbsolute,
    setLang,
    turnLoading,
    offLoading,
    turnMenu,
    offMenu,
    setRoutIsLoading,
    setRoutLoadComplete,
    setRoutIsNotLoadingCauseError,
    setIpAndHostAndSiteAndDefaultLang,
    addErrors,
    removeError,
} = generalStatesSlice.actions;

export default generalStatesSlice.reducer;
