import React from 'react';

import ApiResponse from '@logic/service/ApiRequest/ApiResponse';
import {Tokens} from "@logic/service/ApiRequest/ResponseTypes/Token/TokenResponse";

import LanguagesType from '../../language/types/LanguagesType/LanguagesType';

export default abstract class Helper {
    static isNotChildOfElem (e: React.MouseEvent<HTMLElement>) {
        return e.target === e.currentTarget;
    }

    static handleEnterKeydown<T extends HTMLElement> (e: React.KeyboardEvent<T>, callback: () => void) {
        if (e.key === 'Enter') {
            callback();
        }
    }

    static stopPropagation (e: React.SyntheticEvent) {
        e.stopPropagation();
    }

    static setCookie = (name: string, value: string) => {
        if (process.browser) {
            document.cookie = `${name}=${value}`;
        }
    };


    static getLang = (host?: string) =>{
        if(process.env.NEXT_PUBLIC_SITE as 'ru' | 'com' | 'uz' === 'ru') {
            return LanguagesType.RU;
        }
        if(process.env.NEXT_PUBLIC_SITE as 'ru' | 'com' | 'uz' === 'com') {
            return LanguagesType.EN;
        }
        if(process.env.NEXT_PUBLIC_SITE as 'ru' | 'com' | 'uz' === 'uz') {
            return LanguagesType.UZ;
        }

        if(host?.includes(`pay-assistant.com`)) {
            return LanguagesType.EN;
        } else {
            return LanguagesType.RU;
        }
    };


    static downloadFileFromOctetStream = (response: ApiResponse<any>) => {
        if (!process.browser) {
            return;
        }

        const fileName = response.getHeaders()['file-name'];
        const bytesString = Buffer.from(JSON.parse(fileName), 'base64').toString('utf-8');
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.getData()]));
        link.setAttribute('download', bytesString);
        link.click();
        link.remove();
    };


    static getCookie = (name: string, host?: string): string => {
        if (process.browser) {
            const matches =
        document.cookie.match(
            new RegExp(
                `(?:^|; )` +
              name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, `\\$1`) +
              `=([^;]*)`
            )
        ) || [];
            if(matches.length === 0) {
                return Helper.getLang(host);
            }
            const lang = decodeURIComponent(matches[1]);


            if(lang === 'uz' && host?.includes('uz')) {
                return LanguagesType.UZ;
            }

            return LanguagesType[lang.toUpperCase() as | 'RU' | 'UZ' | 'LT' | 'EN'] ?? LanguagesType.EN;
        }

        return Helper.getLang(host);

    };

    static getRemainingTime = (remainingTimeOfCode: number) => {
        const minutes = parseInt(String(remainingTimeOfCode / 60));
        const seconds = remainingTimeOfCode % 60;

        const timeFormat = (value : number) : string => {
            if(value === 0) return `00`;
            if(value < 10) return `0${value}`;
            return `${value}`;
        };
        return `${timeFormat(minutes)}:${timeFormat(seconds)}`;
    };

    static getValidPath = (route: string) => {
        if(route[route.length - 1] === `/`) {
            return route;
        }
        return route + `/`;
    };

    static isValidEmail (email: string) : boolean
    {
        const regExp = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return regExp.test(email);
    }

    public static round (value : number) : number
    {
        return Math.round((value ?? 0) * 100) / 100;
    }
    public static roundWithoutNan (value : number) : number
    {
        const rounded = Math.round((value ?? 0) * 100) / 100;
        return isNaN(rounded) ? 0 : rounded;
    }

    public static hasNotInvalidSymbols (text: string) {
        const regex = new RegExp(/^[\u0020-\u007E\u0410-\u044F\u0451\u0401\u00AB\u00BB]+$/);
        return (regex.test(text) || text === '');
    }

    public static hasAuthTokens () {
        return !!localStorage.getItem(Tokens.ACCESS_TOKEN) && !!localStorage.getItem(Tokens.REFRESH_TOKEN);
    }

    public static copyText (text: string) {
        window.navigator.clipboard.writeText(text);
    }
}
