import localFont from 'next/font/local';
import { css } from 'styled-components';

import { Breakpoints } from './breakpoints';
import { mediaBreakpointDown } from './functions';

const golosText = localFont({
    src: [
        {
            path: '../../.././public/fonts/Golos-Text_Regular.ttf',
            weight: '400',
            style: 'normal',
        },
        {
            path: '../../.././public/fonts/Golos-Text_Medium.ttf',
            weight: '500',
            style: 'normal',
        },
        {
            path: '../../.././public/fonts/Golos-Text_DemiBold.ttf',
            weight: '600',
            style: 'normal',
        },
        {
            path: '../../.././public/fonts/Golos-Text_Bold.ttf',
            weight: '700',
            style: 'normal',
        },
    ],
    fallback: ['system-ui', 'arial']
});

export const typography = {
    default: css`
      ${golosText.style};
      font-weight: 400;
    `,
    golosMedium: css`
        ${golosText.style};
        font-weight: 500;
    `,
    demiBold: css`
        ${golosText.style};
        font-weight: 600;
    `,
    bold: css`
        ${golosText.style};
        font-weight: 700;
    `,

    // New typography:
    title1: css`
    ${golosText.style};
    font-weight: 600;
    font-size: 40px;
    line-height: 1.3;

    ${mediaBreakpointDown(Breakpoints.xMedium)} {
        font-size: 39px;
    }

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        font-size: 38px;
    }

    ${mediaBreakpointDown(Breakpoints.xMobile)} {
        font-size: 37px;
    }

    ${mediaBreakpointDown(Breakpoints.MS)} {
        font-size: 28px;
    }
    `,
    title2: css`
    ${golosText.style};
    font-weight: 600;
    font-size: 34px;
    line-height: 1.3;

    ${mediaBreakpointDown(Breakpoints.xMedium)} {
        font-size: 32px;
    }

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        font-size: 31px;
    }

    ${mediaBreakpointDown(Breakpoints.xMobile)} {
        font-size: 29px;
    }
    `,
    heading1: css`
        ${golosText.style};
        font-weight: 600;
        font-size: 26px;
        line-height: 1.3;

        ${mediaBreakpointDown(Breakpoints.xMedium)} {
            font-size: 23px;
        }

        ${mediaBreakpointDown(Breakpoints.xTablet)} {
            font-size: 23px;
        }

        ${mediaBreakpointDown(Breakpoints.xMobile)} {
            font-size: 21px;
        }
    `,
    heading2: css`
        font-family: 'GolosText-DemiBold', sans-serif;
        font-size: 20px;
        line-height: 1.4;

        ${mediaBreakpointDown(Breakpoints.xMedium)} {
            font-size: 19px;
        }

        ${mediaBreakpointDown(Breakpoints.xTablet)} {
            font-size: 18px;
        }

        ${mediaBreakpointDown(Breakpoints.Tablet)} {
            font-size: 17px;
        }
    `,
    heading3: css`
        ${golosText.style};
        font-weight: 500;
        font-size: 17px;
        line-height: 1.4;

        ${mediaBreakpointDown(Breakpoints.xMedium)} {
            font-size: 16px;
        }

        ${mediaBreakpointDown(Breakpoints.xTablet)} {
            font-size: 15px;
        }

        ${mediaBreakpointDown(Breakpoints.Tablet)} {
            font-size: 14px;
        }
    `,
    documentation: css`
        ${golosText.style};
        font-weight: 400;
        font-size: 13px;
        line-height: 1.4;

        ${mediaBreakpointDown(1280)} {
            line-height: 1.3;
        }

        ${mediaBreakpointDown(768)} {
            font-size: 12px;
            line-height: 1.2;
        }
    `,
    bodyMedium: css`
        ${golosText.style};
        font-weight: 500;
        font-size: 17px;
        line-height: 1.4;

        ${mediaBreakpointDown(Breakpoints.xMedium)} {
            font-size: 15px;
        }

        ${mediaBreakpointDown(Breakpoints.xTablet)} {
            font-size: 14px;
        }

        ${mediaBreakpointDown(Breakpoints.Tablet)} {
            font-size: 13px;
        }
    `,
    body: css`
        ${golosText.style};
        font-weight: 400;
        font-size: 17px;
        line-height: 1.4;

        ${mediaBreakpointDown(Breakpoints.xMedium)} {
            font-size: 15px;
        }

        ${mediaBreakpointDown(Breakpoints.xTablet)} {
            font-size: 15px;
        }
        
        ${mediaBreakpointDown(Breakpoints.xMobile)} {
            font-size: 13px;
        }
    `,
    bodySmall: css`
        ${golosText.style};
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4;

        ${mediaBreakpointDown(Breakpoints.xTablet)} {
            font-size: 15px;
        }

        ${mediaBreakpointDown(Breakpoints.xMobile)} {
            font-size: 12px;
        }
    `,
    captions: css`
        ${golosText.style};
        font-weight: 400;
        font-size: 14px;
        line-height: 1.3;

        ${mediaBreakpointDown(Breakpoints.xMedium)} {
            font-size: 12px;
        }
    ` ,
    button: css` ${golosText.style};
        font-weight: 500;
        font-size: 17px;
        line-height: 1.4;

        ${mediaBreakpointDown(Breakpoints.xTablet)} {
            font-size: 15px;
        }

        ${mediaBreakpointDown(Breakpoints.xMobile)} {
            font-size: 13px;
        }

    `,

    // Old typography:

    h1: css`
        ${golosText.style};
        font-weight: 700;
        font-size: 44px;
        line-height: 1.4;

        ${mediaBreakpointDown(Breakpoints.Medium)} {
            font-size: 28px;
            line-height: 1.2;
        }

        ${mediaBreakpointDown(Breakpoints.M)} {
            font-size: 24px;
        }
    `,
    h2: css`
        ${golosText.style};
        font-weight: 600;
        font-size: 40px;
        line-height: 1.2;

        ${mediaBreakpointDown(Breakpoints.Medium)} {
            font-size: 32px;
        }

        ${mediaBreakpointDown(Breakpoints.M)} {
            font-size: 20px;
        }
    `,
    text_xl: css`
        ${golosText.style};
        font-weight: 700;
        font-size: 20px;
        line-height: 1.2;
        
        ${mediaBreakpointDown(Breakpoints.M)} {
            font-size: 18px;
        }
    `,
    text_l: css`
        ${golosText.style};
        font-weight: 400;
        font-size: 18px;
        line-height: 1.4;
        
        ${mediaBreakpointDown(Breakpoints.M)} {
            font-size: 16px;
        }
    `,
    text_m: css`
        ${golosText.style};
        font-weight: 500;
        font-size: 16px;
        line-height: 1.4;
        
        ${mediaBreakpointDown(Breakpoints.Large)} {
            font-size: 14px;
        }
    `,
    text_s: css`
        ${golosText.style};
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        
        ${mediaBreakpointDown(Breakpoints.M)} {
            font-size: 13px;
        }
    `,
    text_xs: css`
        ${golosText.style};
        font-weight: 400;
        font-size: 13px;
        line-height: 1.4;
    `,
};

export type Typography = keyof typeof typography;