import { useState} from "react";
import {useDispatch} from "react-redux";

import RuleMessage from "@logic/forms/validators/rules/RuleMessage";
import { ApiErrorAdapter } from "@logic/service/API/error/errorsAdapter";

import {addErrors} from "@store/reducers/generalStates/generalStatesSlice";

import { PayloadAction } from "@reduxjs/toolkit";

export function usePaApiService<T> (
    Service: { new (setError: (error:PayloadAction) => void): T },
    ignoreFields?: string[],
    title?: RuleMessage,
) {


    const dispatch = useDispatch();

    const [apiService, setApiService] = useState<T>(new Service((error) => {
        dispatch(addErrors({
            error: ApiErrorAdapter.getPayAssistantError(error), ignoreFields, title}));
    }));


    return apiService;
}