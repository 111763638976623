
import { SwitchedTaskData } from '@logic/models/Tasks/SwitchedTaskData';
import {UserDetail} from '@logic/models/User/UserNew';
import UserRole from '@logic/models/UserRole/UserRole';
import {ApiMethods} from '@logic/service/ApiRequest/ApiMethods';
import ApiResponse from '@logic/service/ApiRequest/ApiResponse';
import AppealRequest from '@logic/service/ApiRequest/RequestTypes/AppealRequest/AppealRequest';
import {CheckCode} from '@logic/service/ApiRequest/RequestTypes/Auth/CheckCode';
import FirstStepRequest from '@logic/service/ApiRequest/RequestTypes/Auth/FirstStepRequest';
import ConfirmationResponse from '@logic/service/ApiRequest/ResponseTypes/Auth/ConfirmationResponse';
import LoginSessionResponse, {
    AuthSessionResponse
} from '@logic/service/ApiRequest/ResponseTypes/Auth/LoginSessionResponse';
import RegistrationResponse from '@logic/service/ApiRequest/ResponseTypes/Auth/RegistrationResponse';
import PhoneCodesResponse, {CountryCodes} from '@logic/service/ApiRequest/ResponseTypes/Countries/PhoneCodesResponse';
import ExchangeRatesResponse from '@logic/service/ApiRequest/ResponseTypes/ExchangeRatesResponse/ExchangeRatesResponse';
import Bank131VerificationResultResponse
    from '@logic/service/ApiRequest/ResponseTypes/SelfEmployed/Bank131VerificationResultResponse';
import UserResponse, {AuthResponse} from '@logic/service/ApiRequest/ResponseTypes/User/UserResponse';
import AbstractApiService from "@logic/service/ApiService/AbstractApiService";
import Request from '@logic/service/ApiService/Request/Request';

import AuthRequest from '../ApiRequest/RequestTypes/Auth/AuthRequest';


export default class ApiService extends AbstractApiService {


    constructor (props: any) {
        super(props);
    }

    /**
     *
     * @return {Promise<ApiResponse<RegistrationResponse>>}
     */
    public async getSession () : Promise<ApiResponse<RegistrationResponse>>
    {
        const request = new Request({
            url: ApiMethods.Session,
        });
        return await this.apiRequest.sendGet<RegistrationResponse>(request);
    }

    /**
     *
     * @return {Promise<ApiResponse<AuthSessionResponse>>}
     */
    public async getLoginSession () : Promise<ApiResponse<AuthSessionResponse>>
    {
        const request = new Request({
            url: ApiMethods.AuthSession,
        });
        return await this.apiRequest.sendGet<AuthSessionResponse>(request);
    }

    /**
     *
     * @return {Promise<ApiResponse<PhoneCodesResponse>>}
     */
    public async getPhoneCodes () : Promise<ApiResponse<PhoneCodesResponse>>
    {
        const request = new Request({
            url: ApiMethods.PhoneCodes,
        });
        return await this.apiRequest.sendGet<PhoneCodesResponse>(request);
    }


    /**
     *
     * @return {Promise<ApiResponse<PhoneCodesResponse>>}
     */
    public async getCountriesData () : Promise<ApiResponse<CountryCodes>>
    {
        const request = new Request({
            url: ApiMethods.CountryCodes,
        });
        return await this.apiRequest.sendGet<CountryCodes>(request);
    }


    /**
     *
     * @param {LoginRequest} data
     * @return {Promise<ApiResponse<LoginSessionResponse>>}
     */
    public async login (data: AuthRequest) : Promise<ApiResponse<LoginSessionResponse>>
    {
        const request = new Request<AuthRequest>({
            url: ApiMethods.Authentication,
            body: data,
        });
        return await this.apiRequest.sendPost<LoginSessionResponse>(request);
    }


    /**
     *
     * @param {FirstStepRequest} data
     * @return {Promise<ApiResponse<RegistrationResponse>>}
     */
    public async registrationFirstStep (data: FirstStepRequest) : Promise<ApiResponse<RegistrationResponse>>
    {
        const request = new Request<FirstStepRequest>({
            url: ApiMethods.RegistrationFirstStep,
            body: data
        });
        return await this.apiRequest.sendPost<RegistrationResponse>(request);
    }

    /**
     *
     * @return {Promise<ApiResponse<ConfirmationResponse>>}
     */
    public async sendPhoneCode () : Promise<ApiResponse<ConfirmationResponse>>
    {
        const request = new Request<FirstStepRequest>({
            url: ApiMethods.RegistrationPhoneConfirmationSend,
        });
        return await this.apiRequest.sendPost<ConfirmationResponse>(request);
    }

    /**
     *
     * @return {Promise<ApiResponse<ConfirmationResponse>>}
     */
    public async sendEmailCode () : Promise<ApiResponse<ConfirmationResponse>>
    {
        const request = new Request<FirstStepRequest>({
            url: ApiMethods.RegistrationEmailConfirmationSend,
        });
        return await this.apiRequest.sendPost<ConfirmationResponse>(request);
    }

    /**
     *
     * @param {CheckCode} data
     * @return {Promise<ApiResponse<ConfirmationResponse>>}
     */
    public async checkPhoneCode (data: CheckCode) : Promise<ApiResponse<ConfirmationResponse>>
    {
        const request = new Request({
            url: ApiMethods.RegistrationPhoneConfirmationCheck,
            templates: [data.code]
        });
        return await this.apiRequest.sendPost<ConfirmationResponse>(request);
    }

    /**
     *
     * @param {CheckCode} data
     * @return {Promise<ApiResponse<ConfirmationResponse>>}
     */
    public async sendLoginCode () : Promise<ApiResponse<ConfirmationResponse>>
    {
        const request = new Request({
            url: ApiMethods.LoginPhoneConfirmationSend,
        });
        return await this.apiRequest.sendPost<ConfirmationResponse>(request);
    }

    /**
     *
     * @param {CheckCode} data
     * @return {Promise<ApiResponse<ConfirmationResponse>>}
     */
    public async checkLoginCode (data: CheckCode) : Promise<ApiResponse<AuthResponse>>
    {
        const request = new Request({
            url: ApiMethods.LoginPhoneConfirmationCheck,
            templates: [data.code]
        });
        return await this.apiRequest.sendPost<AuthResponse>(request);
    }


    /**
     *
     * @param {CheckCode} data
     * @return {Promise<ApiResponse<ConfirmationResponse>>}
     */
    public async checkEmailCode (data: CheckCode) : Promise<ApiResponse<ConfirmationResponse>>
    {
        const request = new Request({
            url: ApiMethods.RegistrationEmailConfirmationCheck,
            templates: [data.code]
        });
        return await this.apiRequest.sendPost<ConfirmationResponse>(request);
    }

    /**
     *
     * @return {Promise<ApiResponse<AuthResponse>>}
     */
    public async registrationComplete () : Promise<ApiResponse<AuthResponse>>
    {
        const token = localStorage.getItem('INVITE_TOKEN');
        localStorage.removeItem('INVITE_TOKEN');
        const request = new Request<{ token: string | null }>({
            url: ApiMethods.RegistrationComplete,
            body: {
                token: token ?? null
            }
        });

        return await this.apiRequest.sendPost<AuthResponse>(request);
    }


    /**
     *
     * @return {Promise<ApiResponse<UserResponse>>}
     */
    public async getUser () : Promise<ApiResponse<UserResponse>>
    {
        const request = new Request({
            url: ApiMethods.User,
        });
        return await this.apiRequest.sendGet<UserResponse>(
            request
        );
    }

    /**
     *
     * @return {Promise<ApiResponse<UserResponse>>}
     */


    public async checkIndividual (tin: string) : Promise<ApiResponse<Bank131VerificationResultResponse>> {
        const request = new Request<{
            tin: string,
        }>({
            url: ApiMethods.IndividualVerification,
            body: {
                tin,
            }
        });
        return await this.apiRequest.sendPost<Bank131VerificationResultResponse>(request);
    }


    public async getEmailByToken (token: string) : Promise<ApiResponse<{ email: string }>>
    {
        const request = new Request({
            url: ApiMethods.INVITATION_FREELANCER_TOKEN,
            templates: [token]
        });
        return await this.apiRequest.sendPost<{ email: string }>(request);
    }


    public async getCourses () : Promise<ApiResponse<ExchangeRatesResponse>>
    {
        const request = new Request({
            url: ApiMethods.parseCourses,
        });
        return await this.apiRequest.sendGet<ExchangeRatesResponse>(request);
    }


    public async setTaskStatus (id: number, switchedTaskData: SwitchedTaskData) : Promise<ApiResponse<any>>
    {
        const request = new Request<SwitchedTaskData>
        ({
            url: ApiMethods.setTaskStatus,
            templates: [id],
            body: switchedTaskData
        });
        return await this.apiRequest.sendPost<any>(request);
    }

    public async setTasksStatus (taskIds: Array<number>, data: SwitchedTaskData) : Promise<ApiResponse<any>>
    {
        const request = new Request<{taskIds: Array<number>, switchedTaskStatus: SwitchedTaskData}>({
            url: ApiMethods.setTasksStatus,

            body: {
                taskIds,
                switchedTaskStatus: data
            }
        });
        return await this.apiRequest.sendPost<any>(request);
    }


    public async changePassword (oldPassword: string, newPassword: string) : Promise<ApiResponse<AuthResponse>>
    {
        const request = new Request<{oldPassword: string, newPassword: string}>({
            url: ApiMethods.ChangePassword,
            body: {
                oldPassword,
                newPassword
            }
        });
        return await this.apiRequest.sendPost<AuthResponse>(request);
    }


    public async resetPassword (email: string) : Promise<ApiResponse<AuthResponse>>
    {
        const request = new Request({
            url: ApiMethods.ResetPassword,
            templates: [email]
        });
        return await this.apiRequest.sendPost<AuthResponse>(request);
    }

    public async resetToken (token: string) : Promise<ApiResponse<UserDetail>>
    {
        const request = new Request<{token: string}>({
            url: ApiMethods.CheckResetToken,
            body: {
                token
            }
        });
        return await this.apiRequest.sendPost<UserDetail>(request);
    }


    public async refreshPassword (token: string, email: string, newPassword: string) : Promise<ApiResponse<AuthResponse>>
    {
        const request = new Request<{token: string, newPassword: string, email: string}>({
            url: ApiMethods.RefreshPassword,
            body: {
                token,
                newPassword,
                email
            }
        });
        return await this.apiRequest.sendPost<AuthResponse>(request);
    }

    
    public async setEntryTime (roleId: number, userRole: UserRole): Promise<ApiResponse<any>> {
        const request = new Request<{ roleId: number, userRole: UserRole}>({
            url: ApiMethods.SetEntryTime,
            body: {
                roleId,
                userRole
            }
        });

        return await this.apiRequest.sendPost<any>(request);
    }
    public async setAccountEntryTime (accountId: number): Promise<ApiResponse<any>> {
        const request = new Request<{ accountId: number}>({
            url: ApiMethods.SetAccountEntryTime,
            templates: [accountId]
        });

        return await this.apiRequest.sendPost<any>(request);
    }

    public async sendAppealForm (body: AppealRequest): Promise<ApiResponse<any>> {
        const request = new Request<AppealRequest>({
            url: ApiMethods.SendAppeal,
            body
        });

        return await this.apiRequest.sendPost<any>(request);
    }
}