import { ErrorResponse } from "@logic/forms/validators/rules/RuleMessage";

import { SerializedError } from "@reduxjs/toolkit";
import { createApi,FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";

import { baseQueryWithAuth } from "../baseQuery/baseQueryWithAuth";

export const API = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithAuth,
    tagTypes: [
        'User',
        
        'Company', 
        'CompanyAccount', 
        'CompanyTasks', 
        'CompanyTaskInfo',
        'CompanySettings',
        'CompanyFreelancers',
        'CompanyFinance',
        'CompanyInvoices',
        'CompanyStatistic',

        'Freelancer', 
        'FreelancerTasks',
        'FreelancerAccounts',
        'FreelancerTasksFiles',
        'FreelancerSettings',
        'AvalableFreelancerTypes', 
        'FreelancerPayments', 
        'FreelancerCompanies',
        'Autopay'],
    endpoints: () => ({}),
});

export type MutationResponse<T> = Promise<{ data: T; } | { error: FetchBaseQueryError | ErrorResponse | SerializedError; }>;