const titles: {
    [key: string]: {
        ru: string;
        en: string;
        lt: string;
        uz: string;
    };
} = {
    finance: {
        ru: `Финансы`,
        en: `Finance`,
        lt: `Finansai`,
        uz: ''
    },
    task: {
        ru: 'Задачи',
        en: 'Tasks',
        uz: 'Vazifalar',
        lt: 'Užduotys'
    },
    freelancerSettings: {
        ru: 'Настройки фрилансера',
        en: 'Freelancer settings',
        lt: 'Nustatymai',
        uz: 'Sozlamalar'
    }
};

export default titles;
