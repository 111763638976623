import React from "react";

import { RoutLoading } from '@components/personal/common/commonStyled/routeLoading';
import Portal from "@src/hoc/Portal";

export default function RouterLoading ({isLoading}:{isLoading: boolean}) {
    return (
        // @ts-ignore
        <Portal>
            <RoutLoading load={isLoading}>
                <span className={'spinner'}/>
            </RoutLoading>
        </Portal>
    );
}