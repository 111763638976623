import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {AppProps} from 'next/app';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {createWrapper} from 'next-redux-wrapper';

import SiteType from '@logic/enums/TypeSite';
import Helper from '@logic/helpers/Helper/Helper';
import LanguagesType from '@logic/language/types/LanguagesType/LanguagesType';

import {useAppSelector} from '@store/hooks/hooks';
import { store } from '@store/index';
import {
    setIpAndHostAndSiteAndDefaultLang,
    setLang,
    setRoutIsLoading,
    setRoutIsNotLoadingCauseError,
    setRoutLoadComplete
} from '@store/reducers/generalStates/generalStatesSlice';

import { ReCaptchaProvider } from '@components/metrics/recapcha/ReCaptchaProvider';
import GlobalPopupErrors from '@src/common/errors/GlobalPopupErrors/GlobalPopupErrors';
import GlobalLoader from '@src/common/loaders/GlobalLoader/GlobalLoader';
import RouterLoading from "@src/common/loaders/RouterLoading/RouterLoading";
import PhoneCodesLoader from '@src/dataLoaders/PhoneCodesLoader';

import '@styles/index.scss';
import '@styles/landing/components/header/header.scss';
import '@styles/globals.css';

import '@logic/declare/StringExtension';

function MyApp (props: AppProps & {ip: string, host: string}) {

    const dispatch = useDispatch();
    const router = useRouter();
    const {loading, routIsLoading} = useAppSelector(state => state.generalStatesSlice);
    const {Component, pageProps} = props;

    const getSite = () => process.env.NEXT_PUBLIC_SITE as SiteType;

    useEffect(()=>{
        console.warn('_app use effect ip and lang');
        const {ip, host} = props;
        const site = getSite();
        if (ip) {
            dispatch(setIpAndHostAndSiteAndDefaultLang({ip, host, site}));
        }

        if(site === SiteType.COM && !/company-account|freelancer-account/.test(router.asPath)) {
            dispatch(setLang(LanguagesType.EN));
            return;
        }
        const key = router.query.lang;
        if (key === 'ru') {
            router.query.lang = '';
            dispatch(setLang(LanguagesType.RU));
        } else {
            dispatch(setLang(Helper.getCookie(`lang`, host)));
        }

    }, [dispatch, props, router.query]);

    useEffect(()=>{
        console.warn('_app use effect routers start');
        router.events.on('routeChangeStart', ()=> dispatch(setRoutIsLoading()));
        router.events.on('routeChangeComplete', ()=> dispatch(setRoutLoadComplete()));
        router.events.on('routeChangeError', ()=> dispatch(setRoutIsNotLoadingCauseError()));

        return () => {
            console.warn('_app use effect return');
            router.events.off('routeChangeStart', ()=>dispatch(setRoutLoadComplete()));
            router.events.off('routeChangeComplete', ()=>dispatch(setRoutLoadComplete()));
            router.events.off('routeChangeError', ()=>dispatch(setRoutLoadComplete()));
        };
    }, []);

    return (
        <ReCaptchaProvider>
            <Head>
                <link rel="shortcut icon" href="/favicon.ico" />
            </Head>
            <PhoneCodesLoader/>
            <Component {...pageProps} />
            <RouterLoading isLoading={routIsLoading && !loading}/>
            <GlobalLoader loading={loading}/>
            <GlobalPopupErrors/>

        </ReCaptchaProvider>
    );
}

const makeStore = () => store;

const wrapper = createWrapper(makeStore);


MyApp.getInitialProps = async (context: any) => {
    const { req } = context?.ctx ? context?.ctx : context;

    if (req?.headers['x-forwarded-for']) {
        return {ip: req?.headers['x-forwarded-for']?.split(',')[0], host: req.headers.host};
    }
    if (req?.headers['x-real-ip']) {
        return {ip:req?.connection?.remoteAddress, host: req.headers.host};
    }

    return {ip: req?.connection?.remoteAddress, host: req?.headers?.host};

};


export default wrapper.withRedux(MyApp);
