import titles from '@logic/language/ex/account/common/requestTitles';
import { AutoPayDataFreelancer, AutoPayDataFreelancerRequest } from '@logic/models/AutoPayDataFreelancer/AutoPayDataFreelancer';
import { Currency } from '@logic/models/Currency/Currency';
import FiltersOfPage from '@logic/models/Filters/FiltersOfPage';
import { PaymentMethodsByCurrency } from '@logic/models/PaymentMethodsByCurrency/PaymentMethodsByCurrency';
import PagedData from '@logic/models/Table/PagedData';
import { WithdrawalResponseData } from '@logic/models/Withdrawal/WithdrawalData';
import { ApiMethods} from '@logic/service/ApiRequest/ApiMethods';
import FindCompanyTasksResponse, { FreelancerPayment } from '@logic/service/ApiRequest/ResponseTypes/Finance/FreelancerHistoryResponse';
import { AutoPayDataFreelancerResponse } from '@logic/service/ApiService/ResponseTypes/AutoPayDataResponse';

import { API } from '../baseAPI/API';

type AutoPayDataFreelancerMap = Record<Currency, AutoPayDataFreelancer>;

export const financeAPI = API.injectEndpoints({
    endpoints: (builder) => ({
        createPayment: builder.mutation<void, WithdrawalResponseData>({
            query: (body) => ({
                url: ApiMethods.PaymentCreate,
                method: 'POST',
                body
            }),
            invalidatesTags: ['FreelancerPayments'],
            extraOptions: {
                title: titles.finance
            }
        }),
        getFreelancerAutoPayData: builder.query<AutoPayDataFreelancerMap, number>({
            query: (id) => ApiMethods.GetFreelancerAutoPayData.replace('{0}', id.toString()),
            extraOptions: {
                title: titles.finance
            },
            providesTags: ['Autopay'],
            transformResponse: (baseQueryReturnValue: AutoPayDataFreelancerResponse) => {
                const data = {} as AutoPayDataFreelancerMap;
                for (let i = 0; i < baseQueryReturnValue.length; i++) {
                    const autoPayData = baseQueryReturnValue[i];
                    data[autoPayData.accountCurrency] = autoPayData;
                }
                return data;
            },
        }),
        addFreelancerAutoPaySettings: builder.mutation<void, AutoPayDataFreelancerRequest>({
            query: (body) => ({
                url: ApiMethods.SetFreelancerAutoPaySettings,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Autopay'],
            extraOptions: {
                title: titles.finance
            }
        }),
        toggleAutoPaySettings: builder.mutation<AutoPayDataFreelancerResponse, {id: number, isActive: boolean, password: string}>({
            query: (body) => ({
                url: ApiMethods.ToggleFreelancerSettings,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['Autopay'],
            extraOptions: {
                title: titles.finance
            }
        }),
        removeAccountNumber: builder.mutation<void, {settingsId: number, accountNumberId: number}>({
            query: (body) => ({
                url: ApiMethods.RemoveAccountNumberFromSettingsHistory,
                method: 'DELETE',
                body
            }),
            invalidatesTags: ['Autopay'],
            extraOptions: {
                title: titles.finance
            }
        }),
        searchFreelancerPayments: builder.query<PagedData<FreelancerPayment>, {freelancerId: number, filters: FiltersOfPage}>({
            query: ({freelancerId, filters}) => ({
                url: ApiMethods.SearchFreelancerPayments.replace('{0}', freelancerId.toString()),
                method: 'POST',
                body: filters
            }),
            providesTags: ['FreelancerPayments'],
            transformResponse: (response: FindCompanyTasksResponse) => ({
                page: response?.pageNumber ?? 1,
                maxPage: response?.totalPages ?? 1,
                data: response?.payments ?? [],
            }),
        }),
        getPaymentMethodsByCurrency: builder.query<PaymentMethodsByCurrency, number>({
            query: (freelancerId) => ApiMethods.PaymentMethodsByCurrency.replace('{0}', freelancerId.toString()),
        }),
    })
});

export const {
    useCreatePaymentMutation,
    useGetFreelancerAutoPayDataQuery,
    useAddFreelancerAutoPaySettingsMutation,
    useToggleAutoPaySettingsMutation,
    useRemoveAccountNumberMutation,
    useLazySearchFreelancerPaymentsQuery,
    useSearchFreelancerPaymentsQuery,
    useGetPaymentMethodsByCurrencyQuery} = financeAPI;