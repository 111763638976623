
export enum Tokens {
    ACCESS_TOKEN = 'AccessToken',
    REFRESH_TOKEN = 'refreshToken',
}


interface TokenResponse
{
    accessToken: string
    refreshToken: string
}

export default TokenResponse;