import RuleMessage, { ErrorResponse } from '@logic/forms/validators/rules/RuleMessage';
import { ApiMethods} from '@logic/service/ApiRequest/ApiMethods';
import TokenResponse, { Tokens } from '@logic/service/ApiRequest/ResponseTypes/Token/TokenResponse';

import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import { baseQuery } from './baseQuery';

const mutex = new Mutex();

interface ExtraOptions {
    title?: RuleMessage, 
    notAuthorized?: boolean
}

export const baseQueryWithAuth: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError | ErrorResponse,
ExtraOptions
> = async (args, api, extraOptions) => {

    const queryTitle = extraOptions?.title;
    const notAuthorizedRequest = extraOptions?.notAuthorized;

    const getArgsConfig = () => notAuthorizedRequest ? args : getAuthorizedArgsConfig(args);
    
    let result = await baseQuery(
        getArgsConfig(),
        api, 
        extraOptions);
    
    if (result?.error && result.error?.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            
            try {
                const res = await refreshTokensQuery(args, api, extraOptions);
                result = res ? res : result;
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            result = await baseQuery(
                getArgsConfig(),
                api, 
                extraOptions);
        }
    }

    if(result.error && queryTitle) {
        return {...result, meta: {...result.meta, title: queryTitle}};
    }
    
    return result;
};

const refreshTokensQuery = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: ExtraOptions) => {
    const body = {
        refreshToken: localStorage.getItem(Tokens.REFRESH_TOKEN)!,
        accessToken: localStorage.getItem(Tokens.ACCESS_TOKEN)!
    };

    const refreshResult = await baseQuery(
        {
            url: ApiMethods.RefreshToken,
            method: 'POST',
            body
        },
        api,
        extraOptions,
    );

    if (refreshResult.data) {
        localStorage.setItem(Tokens.REFRESH_TOKEN, (refreshResult.data as TokenResponse)?.refreshToken);
        localStorage.setItem(Tokens.ACCESS_TOKEN, (refreshResult.data as TokenResponse)?.accessToken);

        // retry the initial query
        return await baseQuery(
            getAuthorizedArgsConfig(args),
            api, 
            extraOptions);
    } else {
        localStorage.removeItem(Tokens.REFRESH_TOKEN);
        localStorage.removeItem(Tokens.ACCESS_TOKEN);
        window.location.href = '/sign-in';
    }
};

/* For a request without authorization headers, you need to set for an endpoint definition -  
    extraOptions: { notAuthorized: true}
    */
const getAuthorizedArgsConfig = (args: string | FetchArgs) => {
    const token = localStorage.getItem(Tokens.ACCESS_TOKEN);
    if (!token) {
        return args;
    }
    const argsConfig = typeof args === 'string' ? { url: args } : args;
    return {
        ...argsConfig,
        headers: {
            ...argsConfig?.headers,
            'Authorization': `Bearer ${token}`
        }
    };
};