import {ApiMethods, ApiMethodsWithoutToken} from '@logic/service/ApiRequest/ApiMethods';
import {Tokens} from "@logic/service/ApiRequest/ResponseTypes/Token/TokenResponse";

import {AxiosRequestConfig} from "axios";

const hostMap = new Set(ApiMethodsWithoutToken);


interface RequestParams {
    [key: string]: string | number
}


export default class Request<BodyT = object, ParamsT extends RequestParams = Record<string, string | number>> {

    private readonly config: AxiosRequestConfig;
    private readonly url: ApiMethods;

    constructor (props: {
        url: ApiMethods,
        params?: ParamsT,
        body?: BodyT,
        templates?: Array<string | number>,
        additionalHeaders?: Record<string, string>
        additionalConfig?: AxiosRequestConfig
    }) {
        const {url, params, body, templates, additionalHeaders = {}, additionalConfig} = props;
        this.url = url;
        this.config = {
            url: url.format(...templates ?? []),
            params: params,
            data: body,
            headers: {
                ...additionalHeaders,
            },
            ...additionalConfig
        };
    }

    public needToken () {
        return !hostMap.has(this.url);
    }


    public getConfig (): AxiosRequestConfig {
        const auth = ((): Record<string, string> => {
            if(!hostMap.has(this.url) && localStorage.getItem(Tokens.ACCESS_TOKEN)) {
                return {
                    'Authorization': `Bearer ` + localStorage.getItem(Tokens.ACCESS_TOKEN) ?? ''
                };
            }
            return {};
        })();

        return {
            ...this.config,
            headers: {
                ...this.config.headers,
                ...auth,
            }
        };
    }

}