import Country from '@logic/models/Country/Country';
import PhoneCodesResponse from '@logic/service/ApiRequest/ResponseTypes/Countries/PhoneCodesResponse';

import IDataSliceType from '@store/reducers/IDataSliceType';

import {createSlice, PayloadAction} from '@reduxjs/toolkit';


const initialState: IDataSliceType<Country[]> = {
    data: [],
    loaded: false
};

export const PhoneCodeSlice = createSlice({
    name: `countriesSlice`,
    initialState,
    reducers: {
        setPhoneCodes: (state, action : PayloadAction<PhoneCodesResponse>) => {
            state.loaded = true;
            state.data = action.payload;
        }
    },
});

export const {setPhoneCodes} = PhoneCodeSlice.actions;
export default PhoneCodeSlice.reducer;
