export enum Colors {
    //Grayscale:
    WHITE = '#ffffff',
    GRAY_100 = '#FAFAFA',
    GRAY_200 = '#F7F7FC',
    GRAY_300 = '#EFF0F6',
    GRAY_400 = '#D7DBEB',
    GRAY_500 = '#B3B7C7',
    GRAY_600 = '#737A92',
    GRAY_700 = '#4E4B66',
    GRAY_800 = '#282C38',
    GRAY_900 = '#1C1C1C',

    //Solid:
    DANGER = '#df2323',
    YELLOW = '#FFC226',
    EMERALD = '#3DE297',
    GREEN = '#2AB408',
    SWAMPY = '#0CAF60',
    NDA = '#188B5B',
    DEFAULT_BLUE = '#7696E7',
    NEW = '#25A7D0',
    PEN_BLUE = '#04103D',

    //Status:
    ERROR = '#FD6A6A',
    CHECKING = '#FF6B00',
    PROCESSING = '#D89B00',
    PAID = '#30D42C',
    BLUE = '#3D6BE2',
    PLAN = '#6A25AE',

    //Backgroud:
    BG_RED = '#FFEFED',
    BG_ORANGE = '#FFF3EC',
    BG_YELLOW = '#FFFFEA',
    BG_SWAMPY = '#E7F7EF',
    BG_MINT = '#E8FFF4',
    BG_BLUE = '#EBF4FA',
    BG_REPORTS_BLUE = '#F1F5FF',
    BG_LIGHT_BLUE = '#F2F9FB',
    BG_LIGHT_PURPLE = '#FAF0FF',

    //Gradient:
    GRADIENT_IP = 'linear-gradient(341deg, #0D5A7B 0%, #2D74DF 100%)',
    GRADIENT_CYAN = 'linear-gradient(135deg, #59F0DE 0%, #093248 100%)',
    GRADIENT_GREENBLUE = 'linear-gradient(135deg, #65E744 0%, #44ACE7 100%)',
    
    VIOLET = '#7B61FF',
    VIOLET_TRANSPARENT = 'rgb(229 221 248 / 30%)',
}
