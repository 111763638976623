import { Currency } from '@logic/models/Currency/Currency';

export const enum Z_INDEX {
    'header' = 9,
    'modal' = 10,
    'popupErrors' = 11,
    'globalLoader' = 1000,
    'navigationMenu' = 10,
}

export const CurrencySymbols = {
    [Currency.EUR]: '€',
    [Currency.RUB]: '₽',
    [Currency.USD]: '$',
    [Currency.USDT]: '₮',
    [Currency.UAH]: '₴',
    [Currency.UZS]: '',
};

export const CurrencyForPrice = {
    [Currency.EUR]: '€',
    [Currency.RUB]: '₽',
    [Currency.USD]: '$',
    [Currency.USDT]: '₮',
    [Currency.UAH]: '₴',
    [Currency.UZS]: 'uzs',
};
