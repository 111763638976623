import RuleMessage, { ErrorType, ValidationError} from '@logic/forms/validators/rules/RuleMessage';
import LanguagesType from '@logic/language/types/LanguagesType/LanguagesType';
import GeneralPopupError from "@logic/models/GeneralPopupError/GeneralPopupError";

export type ErrorConstructor = {
    status: number, 
    message: Array<ValidationError> | ValidationError, 
    type?: ErrorType, 
    title?: RuleMessage,
    networkError?:boolean, 
};

export class PayAssistantError {

    private readonly status: number;
    private readonly type: ErrorType = ErrorType.UNKNOWN;
    private readonly networkError: boolean = false;
    private readonly serviceUnavailable: boolean = false;
    private readonly title?: RuleMessage | null;
    private readonly messages: Array<ValidationError> = [];

    constructor (error: ErrorConstructor) {
        this.status = error.status;
        this.title = error.title ?? null;
        this.serviceUnavailable = !!error.networkError;
        if(error.type) {
            this.type = error.type;
        }
        if(error.networkError) {
            this.networkError = error.networkError;
        }
        if(Array.isArray(error.message)) {
            this.messages = error.message;
        } else {
            this.messages.push(error.message);
        }
    }

    public isBadRequest (): boolean {
        return this.status === 400;
    }

    public isServiceUnavailable (): boolean {
        return this.serviceUnavailable;
    }

    public getErrors (): Array<ValidationError> {
        return this.messages;
    }

    public isServerError (): boolean {
        return this.status >= 500;
    }

    public getPreparedErrors (title?: RuleMessage, ignoreFields?:Array<string>): Array<GeneralPopupError> {
        const result = [];

        if(this.messages.length > 0) {
            for(let i = 0; i < this.messages.length; i++) {
                const message = this.messages[i];
                if(!ignoreFields?.includes(message.field!)) {
                    result.push({
                        id: new Date().getTime() + i,
                        title:  this.title ?? title ?? {
                            ru: 'Ошибка',
                            en: 'Error',
                            lt: 'Klaida',
                            uz: 'Xato',
                        },
                        description: message.text
                    });
                }

            }
        }

        if(this.isServerError() || this.isServiceUnavailable()) {
            result.push({
                id: new Date().getTime() + 1,
                title: this.title ?? title ?? {
                    ru: 'Ошибка',
                    en: 'Error',
                    lt: 'Klaida',
                    uz: 'Xato',
                },
                description: {
                    ru: 'Произошла ошибка сервиса, мы ее уже исправляем',
                    en: 'Service error, we are already fixing it',
                    lt: 'Pasirodė paslaugos klaida, mes ją jau sutvarkome',
                    uz: 'Xizmat xatosi ro\'y berdi, biz uni allaqachon tuzamiz',
                }
            });
        }
        return result;
    }

    public hasValidationError (field: string): boolean {
        if(this.type !== ErrorType.VALIDATION) return false;

        return this.messages.filter(message => message.field === field).length > 0;
    }

    public getErrorTranslationByField (field: string, language: LanguagesType): string {
        if(this.type !== ErrorType.VALIDATION) return '';

        const messages = this.messages.filter(message => message.field === field);
        if(messages.length > 0) {
            return messages[0].text[language];
        }
        return '';
    }

    public getAnyErrorMessage (): RuleMessage {
        if(this.messages.length > 0) {
            return this.messages[0].text;
        }
        return {
            ru: 'Неизвестная ошибка, попробуйте позже',
            en: 'Unknown error, try again later',
            lt: 'Nežinoma klaida, pabandykite vėliau',
            uz: 'Noma\'lum xato, keyinroq harakat qilib ko\'ring',
        };
    }

    public getAnyErrorExcept (fields: Array<string>): RuleMessage | null {
        for(let i = 0; i < this.messages.length; i++) {
            const message = this.messages[i];
            if(!message.field) {
                return message.text;
            }
            if(!fields.includes(message.field)) {
                return message.text;
            }
        }

        if(this.status >= 500) {
            return {
                ru: 'Произошла ошибка сервиса, мы ее уже исправляем',
                en: 'Service error, we are already fixing it',
                lt: 'Pasirodė paslaugos klaida, mes ją jau sutvarkome',
                uz: 'Xizmat xatosi ro\'y berdi, biz uni allaqachon tuzamiz',
            };
        }

        return null;
    }

    public getDefaultErrorMessage (): RuleMessage {
        return {
            ru: 'Неизвестная ошибка, попробуйе позже',
            en: 'Unknown error, try again later',
            lt: 'Nežinoma klaida, pabandykite vėliau',
            uz: 'Noma\'lum xato, keyinroq harakat qilib ko\'ring',
        };
    }
}