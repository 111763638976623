import RuleMessage, { ErrorsResponse, ErrorType, ValidationError} from '@logic/forms/validators/rules/RuleMessage';
import LanguagesType from '@logic/language/types/LanguagesType/LanguagesType';
import GeneralPopupError from "@logic/models/GeneralPopupError/GeneralPopupError";

import { AxiosResponse} from "axios";

import { ApiErrorAdapter } from '../API/error/errorsAdapter';
import { PayAssistantError } from '../API/error/PayAssistantError';


export class ApiErrorResponse {

    private readonly code: number | undefined;
    private readonly title?: RuleMessage | null;
    private readonly errors: ErrorsResponse | null = null;
    private readonly errorType: ErrorType | null = null;
    private readonly serviceUnavailable: boolean = false;

    constructor (errorResponse: AxiosResponse<ErrorsResponse>, networkError?:boolean, title?: RuleMessage | null) {
        if(!networkError && errorResponse?.data?.type) {
            this.errorType = errorResponse.data.type;
            this.errors = errorResponse.data;
        }
        if(errorResponse?.status) {
            this.code = errorResponse.status;
        }
        this.title = title;
        this.serviceUnavailable = !!networkError;
    }

    public isBadRequest (): boolean {
        return this.code === 400;
    }

    public getErrorType (): ErrorType {
        return this.errorType ?? ErrorType.UNKNOWN;
    }

    public isServiceUnavailable (): boolean {
        return this.serviceUnavailable;
    }

    public getErrors (): ErrorsResponse | null {
        return this.errors;
    }

    public isServerError (): boolean {
        return (this.code ?? 500) >= 500;
    }


    public getPreparedErrors (title?: RuleMessage, ignoreFields?:Array<string>): Array<GeneralPopupError> {
        const result = [];

        if(this.errors?.messages) {
            for(let i = 0; i < this.errors.messages.length; i++) {
                const message = this.errors.messages[i];
                if(!ignoreFields?.includes(message.field!)) {
                    result.push({
                        id: new Date().getTime() + i,
                        title:  this.title ?? title ?? {
                            ru: 'Ошибка',
                            en: 'Error',
                            lt: 'Klaida',
                            uz: 'Xato',
                        },
                        description: message.text
                    });
                }

            }
        }
        if(this.isServerError() || this.isServiceUnavailable()) {
            result.push({
                id: new Date().getTime() + 1,
                title: this.title ?? title ?? {
                    ru: 'Ошибка',
                    en: 'Error',
                    lt: 'Klaida',
                    uz: 'Xato',
                },
                description: {
                    ru: 'Произошла ошибка сервиса, мы ее уже исправляем',
                    en: 'Service error, we are already fixing it',
                    lt: 'Pasirodė paslaugos klaida, mes ją jau sutvarkome',
                    uz: 'Xizmat xatosi ro\'y berdi, biz uni allaqachon tuzamiz',
                }
            });
        }
        return result;
    }

    public hasError (field: string): boolean {
        return !!this.errors?.messages && this.errors?.messages?.filter(message => message.field === field).length > 0;
    }

    public getErrorByField (field: string, language: LanguagesType): string {
        const messages = this.errors ? this.errors.messages.filter(message => message.field === field) : [];
        if(messages.length > 0) {
            return messages[0].text[language];
        }
        return '';
    }
    public getAnyError (): RuleMessage {
        if(this.errors) {
            return this.errors.messages[0].text;
        }
        return {
            ru: 'Неизвестная ошибка, попробуйе позже',
            en: 'Unknown error, try again later',
            lt: 'Nežinoma klaida, pabandykite vėliau',
            uz: 'Noma\'lum xato, keyinroq harakat qilib ko\'ring',
        };
    }

    public getAnyErrorExcept (fields: Array<string>): RuleMessage | null {
        const messages = this.errors?.messages ?? [];

        for(let i = 0; i < messages.length; i++) {
            const message = messages[i];
            if(!message.field) {
                return message.text;
            }
            if(!fields.includes(message.field)) {
                return message.text;
            }
        }

        if(this.code && this.code >= 500) {
            return {
                ru: 'Произошла ошибка сервиса, мы ее уже исправляем',
                en: 'Service error, we are already fixing it',
                lt: 'Pasirodė paslaugos klaida, mes ją jau sutvarkome',
                uz: 'Xizmat xatosi ro\'y berdi, biz uni allaqachon tuzamiz',
            };
        }

        return null;
    }

    public getErrorMessage (): RuleMessage | null {

        if(this.errors) {
            return null;
        }

        return {
            ru: 'Неизвестная ошибка, попробуйе позже',
            en: 'Unknown error, try again later',
            lt: 'Nežinoma klaida, pabandykite vėliau',
            uz: 'Noma\'lum xato, keyinroq harakat qilib ko\'ring',
        };
    }


}

export default class ApiResponse<T>
{
    private readonly status : boolean = false;
    private readonly data : T | null = null;
    private readonly code: number;
    private readonly headers: Record<string, string> = {};
    private readonly error: PayAssistantError | null = null;
    private readonly response: AxiosResponse<T> | null = null;

    constructor (response: AxiosResponse<T>) {
        if(!response) {
            this.code = 500;
            this.error = ApiErrorAdapter.getPayAssistantError({data: null, status: 500}, undefined, true);
            return;
        }
        this.response = response;
        this.status = response.status >= 200 && response.status < 300;
        this.code = response.status;
        this.headers = response.headers;
        if(!this.status) {
            this.error = ApiErrorAdapter.getPayAssistantError(response as unknown);
        }
        if(this.status) {
            this.data = response.data;
        }
    }


    public isServerError () : boolean {
        return this.code == 500;
    }

    /**
     *
     * @return {boolean}
     */
    public isSuccess () : boolean
    {
        return this.status;
    }

    /**
     *
     * @return {T}
     */
    public getData () : T
    {
        if(!this.data) {
            throw new Error('Api response data is null');
        }
        return this.data;
    }

    /**
     * @return {ApiErrorResponse}
     */
    public getError () : PayAssistantError | null {
        return this.error;
    }

    public getMessageErrors (): ValidationError[] | null {
        return this.error?.getErrors() ?? null;
    }

    public getHeaders () {
        return this.headers;
    }

    public getResponse () {
        return this.response;
    }
}