import {Currency} from "@logic/models/Currency/Currency";
import {PaymentMethod, PaymentMethodType} from '@logic/models/PaymentMethod/PaymentMethod';
import {financeAPI} from "@logic/service/API/freelancerAPI/financeAPI";
import {
    PaymentMethodsResponse
} from '@logic/service/ApiService/ResponseTypes/PaymentMethodsResponse';

import IDataSliceType from '@store/reducers/IDataSliceType';

import {createSlice, PayloadAction} from '@reduxjs/toolkit';


interface IPaymentMethodSliceType {
    methodsData: PaymentMethod[],
    mapData: Map<PaymentMethodType, string>
}


const initialState: IDataSliceType<IPaymentMethodSliceType> = {
    data: {
        methodsData: [],
        mapData: new Map<PaymentMethodType, string>()
    },
    loaded: false
};


export const paymentMethodSlice = createSlice({
    name: `paymentMethodsSlice`,
    initialState,
    reducers: {
        setPaymentMethods (state, action : PayloadAction<PaymentMethodsResponse>) {
            state.data.methodsData = action.payload;
            action.payload.forEach((method) => {
                state.data.mapData.set(method.type, method?.icon?.payload ?? '');
            });
            state.loaded = true;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(financeAPI.endpoints.getPaymentMethodsByCurrency.matchFulfilled,
            (state, action) => {
                const data: PaymentMethod[] = [];
                const res = action.payload;
                Object.keys(res).forEach((key) => {
                    data.push(...res[key as Currency]);
                });
                state.data.methodsData = data;
                state.data.mapData = new Map<PaymentMethodType, string>();
                data.forEach((method) => {
                    state.data.mapData.set(method.type, method?.icon?.payload ?? '');
                });
            }
        );
    }
});

export const {setPaymentMethods} = paymentMethodSlice.actions;
export default paymentMethodSlice.reducer;

