export enum Currency
    {
    EUR = 'eur',
    UAH = 'uah',
    USD = 'usd',
    USDT = 'usdt',
    RUB = 'rub',
    UZS = 'uzs',
}

