import RuleMessage, { ErrorsResponse} from "@logic/forms/validators/rules/RuleMessage";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import { ErrorConstructor, PayAssistantError } from "./PayAssistantError";

export class ApiErrorAdapter {

    public static getPayAssistantError (error: unknown, title?: RuleMessage, isNetworkError?: boolean): PayAssistantError {
        if(error instanceof PayAssistantError) return error;
        
        const errorData: ErrorConstructor = {
            status: 0,
            title,
            message: [],
            networkError: isNetworkError
        };

        if(ApiErrorAdapter.isErrorWithStatus(error)) {
            if(typeof error.status === 'number') {
                errorData.status = error.status;
            }
            if(typeof error.status === 'string' && !errorData.networkError) {
                errorData.networkError = error.status === "FETCH_ERROR";
            }
        }

        if(ApiErrorAdapter.isFetchBaseQueryError(error) 
        && ApiErrorAdapter.isErrorsResponse(error.data)) {
            errorData.type = error.data.type;
            errorData.message = error.data.messages;

            return new PayAssistantError(errorData);
        }

        if(ApiErrorAdapter.isAnyErrorWithMessage(error)) {
            errorData.message = {
                field: '',
                failType: '',
                text: {
                    ru: error.message,
                    en: error.message,
                    uz: error.message,
                    lt: error.message
                },
            };
        }
        
        return new PayAssistantError(errorData);
    }

    /**
     * Type predicate to narrow an unknown error to `FetchBaseQueryError`
     */
    private static isFetchBaseQueryError (error: unknown): error is FetchBaseQueryError {
        return typeof error === 'object' 
        && error != null && 'status' in error && 'data' in error;
    }

    /**
     * Type predicate to narrow an unknown error data to our backend error response
     */
    private static isErrorsResponse (errorData: unknown): errorData is ErrorsResponse {
        return typeof errorData === 'object' 
        && errorData != null 
        && 'messages' in errorData
        && 'type' in errorData
        && 'status' in errorData;
    }
    
    /**
     * Type predicate to narrow an unknown error to an object with a string 'message' property
    */
    private static isAnyErrorWithMessage (error: unknown): error is { message: string } {
        return (typeof error === 'object' &&
        error != null &&
        'message' in error &&
        typeof (error as any)?.message === 'string');
    }

    /**
     * Type predicate to narrow an unknown error to an object with a status' property
    */
    private static isErrorWithStatus (error: unknown): error is { status: string | number} {
        return (typeof error === 'object' &&
        error != null &&
        'status' in error);
    }
}