import {AutoPayDataFreelancer} from '@logic/models/AutoPayDataFreelancer/AutoPayDataFreelancer';
import {Currency} from '@logic/models/Currency/Currency';
import {AutoPayDataFreelancerResponse} from '@logic/service/ApiService/ResponseTypes/AutoPayDataResponse';

import IDataSliceType from '@store/reducers/IDataSliceType';

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type AutoPayDataFreelancerMap = Record<Currency, AutoPayDataFreelancer>;

const initialState: IDataSliceType<AutoPayDataFreelancerMap> = {
    data: {} as AutoPayDataFreelancerMap,
    loaded: false
};

export const autoPaySlice = createSlice({
    name: `paymentMethodsSlice`,
    initialState,
    reducers: {
        setAutoPay (state, action : PayloadAction<AutoPayDataFreelancerResponse>) {
            for (let i = 0; i < action.payload.length; i++) {
                const autoPayData = action.payload[i];
                state.data[autoPayData.accountCurrency] = autoPayData;
            }
            state.loaded = true;
        },
    },
});

export const {setAutoPay} = autoPaySlice.actions;
export default autoPaySlice.reducer;

