import { URL } from '@store/urls';

import {fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const baseQuery = fetchBaseQuery({
    baseUrl: URL,
    credentials: 'include',
    prepareHeaders: (headers) => {    
        headers.set('Content-Type','application/json;charset=UTF-8');

        return headers;
    }
});