import React from 'react';
import styled, {keyframes} from 'styled-components';

import { Colors } from '@components/styledComponents/base/Colors';
import { Z_INDEX } from '@components/styledComponents/base/vars';
import Portal from "@src/hoc/Portal";

type Props = {
    loading: boolean
};
export default function GlobalLoader (props: Props) {

    const {loading} = props;

    return (
        <Portal>
            <Container load={loading}>
                <Spinner load={loading}/>
            </Container>
        </Portal>
    );
}


const Container = styled.div<{load: boolean}>`
  z-index: ${Z_INDEX.globalLoader};
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  
  transition: 1s;
  visibility:  ${({load}) => load ? 'visible' : 'hidden'};
  opacity: ${({load}) => load ? '1' : '0'};
  background: ${({load}) => load ? 'white' : 'transparent'};
`;

const Spin = keyframes`
  from{
    transform: rotate(0deg);
  }to{
     transform: rotate(360deg);
   }
`;


const Spinner = styled.div<{ load: boolean }>`
  width: 80px;
  height: 80px;

  border: 2px solid #f3f3f3;
  border-top:3px solid ${Colors.EMERALD};
  border-radius: 100%;

  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right: 0;
  margin: auto;
  
  transition: .5s;
  
  animation: ${Spin} ${({load})=>load ? '1' : '.5'}s  infinite linear;
`;