import ExchangeRates from '@logic/models/ExchangeRates/ExchangeRates';
import ExchangeRatesResponse from '@logic/service/ApiRequest/ResponseTypes/ExchangeRatesResponse/ExchangeRatesResponse';

import IDataSliceType from '@store/reducers/IDataSliceType';

import {createSlice, PayloadAction} from '@reduxjs/toolkit';


const initialState: IDataSliceType<ExchangeRates> = {
    loaded: false,
    data: {
        courses: [],
        coursesForCalculations: []
    }
};

export const ratesSlice = createSlice({
    name: `rates`,
    initialState,
    reducers: {
        setRates: (state, action : PayloadAction<ExchangeRatesResponse>) => {
            state.data.courses = action.payload.exchangeRateClientRepresentations;
            state.data.coursesForCalculations = action.payload.exchangeRates;
            state.loaded = true;
        }
    },

});

export const {setRates} = ratesSlice.actions;
export default ratesSlice.reducer;
