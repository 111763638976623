import Session from '@logic/models/Session/Session';
import ConfirmationResponse from '@logic/service/ApiRequest/ResponseTypes/Auth/ConfirmationResponse';
import RegistrationResponse, {
    CompleteResponse
} from '@logic/service/ApiRequest/ResponseTypes/Auth/RegistrationResponse';

import IDataSliceType from '@store/reducers/IDataSliceType';

import {createSlice, PayloadAction} from '@reduxjs/toolkit';


const initialState : IDataSliceType<Session> = {
    loaded: false,
    data: {}
};

export const AuthSlice = createSlice({
    name: `authSlice`,
    initialState,
    reducers: {
        clearRegistration : (state) => {
            state.loaded = false;
            state.data = {};
        },
        setSession : (state, action : PayloadAction<RegistrationResponse>) => {
            state.data.registration = action.payload;
            state.loaded = true;
        },
        completeRegistration : (state, action : PayloadAction<CompleteResponse>) => {
            state.loaded = true;
            state.data = {};
        },
        setUserIsLogin : (state) => {
            state.loaded = true;
            state.data = {
                registration: undefined,
                login: {
                    authorized: true
                }

            };
        },
        setRegistration : (state, action : PayloadAction<RegistrationResponse>) => {
            state.loaded = true;
            state.data.registration = {
                isFilled: action.payload.isFilled,
                user: action.payload.user,
                confirmations: action.payload.confirmations
            };
        },
        setPhoneConfirmation : (state, action : PayloadAction<ConfirmationResponse>) => {
            if(!state.data.registration) return;
            state.data.registration.confirmations.phone = action.payload;
        },
        setEmailConfirmation : (state, action : PayloadAction<ConfirmationResponse>) => {
            if(!state.data.registration) return;
            state.data.registration.confirmations.email = action.payload;
        }
    },
});
export const {setSession, setRegistration, setPhoneConfirmation, setEmailConfirmation, completeRegistration, clearRegistration, setUserIsLogin} = AuthSlice.actions;
export default AuthSlice.reducer;

