

export enum ApiMethods
    {
    PhoneCodes = `phone-codes`,
    CountryCodes = `country-codes`,
    Session = `auth/register/session`,
    SendAppeal = `client/appeal`,
    AuthSession = `authentication/session`,
    RegistrationComplete = `auth/register/complete`,
    Login = `auth/login`,
    Authentication = `authentication`,
    RefreshToken = `authentication/refresh-token-pair`,
    RegistrationFirstStep = `auth/register/first-step`,
    RegistrationPhoneConfirmationSend = `auth/register/phone/send`,
    RegistrationPhoneConfirmationCheck = `auth/register/phone/check/{0}`,
    RegistrationEmailConfirmationSend = `auth/register/email/send`,
    RegistrationEmailConfirmationCheck = `auth/register/email/check/{0}`,

    LoginPhoneConfirmationCheck = 'authentication/phone/check/{0}',
    LoginPhoneConfirmationSend = `authentication/phone/send`,
    Categories = `categories`,

    User = `user`,
    ChangePassword = `user/password/change`,
    ResetPassword = `user/password/reset/request/{0}`,
    CheckResetToken = `user/password/reset/check-token`,
    RefreshPassword = `user/password/reset`,
    SendAddress = `user-detail/address-and-dob`,
    SetEntryTime = `user/role/set-entry-time`,

    CompanySetSettings = `company/settings`,
    DownloadDocument = `company/document/download`,
    AvailableDatesForActs = `company/{0}/act-dates`,
    CompanyStatistic = `dashboard/company/{0}/statistic`,
    CreateInvoice = `company/{0}/invoice`,
    GetInvoice = `company/invoice/{0}/file/download`,
    CompanySettings = `company/{0}/settings`,
    CompanyContract = `/company/{0}/contract`,
    Company = `company`,
    Companies = `companies`,
    GetCompanyAccount = `/company/{0}/account`,
    TaskCreate = `company/tasks`,
    TaskImport = `company/import-tasks`,
    Fees = `company/{0}/fee`,
    FindCompany = `company/find/{0}`,
    SearchFreelancers = `company/{0}/search/freelancers`,
    TasksInfo = `company/{0}/tasks/status-count`,
    CompanyOnBoardingComplete = `company/complete-on-boarding`,
    FindCompaniesFreelancers = `company/{0}/freelancers`,
    INVITATION_FREELANCER_TOKEN = `company/invitation/freelancer/check/{0}`,
    CompanyFreelancersQuantity = `company/{0}/freelancers-quantity`,

    Freelancer = `freelancers`,
    CheckFreelancerSelfEmployedIsExist = `freelancer/self-employed/bank131-verification`,
    SelfEmployedWidgetToken = `bank131/widget-token/{0}`,
    SaveSelfEmployed = `freelancer/self-employed`,
    IndividualVerification = `freelancer/individual-entrepreneur/dadata-verification`,
    CompletePersonalOnBoardingUseSumSub = `freelancer/personal/sumsub-verification`,
    ResultPersonalOnBoardingUseSumSub = `freelancer/personal/failed/{0}/sumsub-verification-result`,
    COMPANIES_FOR_FREELANCER = `freelancer/companies`,
    UploadFiles = `freelancer/task/{0}/files/update`,
    PaymentCreate = `freelancer/payment/pay`,
    FreelancerSettings = `freelancer/{0}/settings`,
    FreelancerSetSettings = `freelancer/settings`,
    GetAvailableFreelancerTypes = `freelancer/available-types`,
    GetFreelancerAccounts = `freelancer/{0}/account`,
    GetFreelancerAutoPayData = `freelancer/{0}/auto-payment-settings`,
    SetFreelancerAutoPaySettings = `freelancer/auto-payment-settings`,
    ToggleFreelancerSettings = `freelancer/auto-payment-settings/is-active`,
    RemoveAccountNumberFromSettingsHistory = `freelancer/auto-payment-settings/account-number`,
    PaymentMethodsWithAssignGateways = `freelancer/{0}/payment/methods`,
    PaymentMethodsByCurrency = `freelancer/{0}/payment-methods-by-currencies`,

    FindBank = `bank/find/{0}`,
    parseCourses = `exchange-rates`,
    GetAddressByIP = `address-by-ip`,

    GetFilesByTaskId = `task/{0}/files`,
    DownLoad = `task/{0}/file/{1}/download`,
    setTaskStatus = `task/{0}/set-status`,
    setTasksStatus = `tasks/set-status`,

    SetAccountEntryTime = `account/{0}/set-entry-time`,


    SearchTasksForFreelancer = `search/freelancer/{0}/tasks`,
    searchInvoicesByCompanyId = `search/company/{0}/invoices`,
    searchFinanceForCompany = `search/company/{0}/finance/operation-history`,
    SearchCompanyTasks = `search/company/{0}/tasks`,
    SearchFreelancerPayments = `search/freelancer/{0}/payments`,

}

export const ApiMethodsWithoutToken = [
    ApiMethods.PhoneCodes,
    ApiMethods.Categories,
    ApiMethods.Authentication,
    ApiMethods.RefreshToken,
    ApiMethods.INVITATION_FREELANCER_TOKEN,
    ApiMethods.CountryCodes,
    ApiMethods.RegistrationEmailConfirmationCheck,
    ApiMethods.RegistrationPhoneConfirmationCheck,
    ApiMethods.RegistrationEmailConfirmationSend,
    ApiMethods.RegistrationPhoneConfirmationSend,
    ApiMethods.RegistrationFirstStep,
    ApiMethods.RegistrationComplete,
    ApiMethods.Session,


    'company/{0}/employee/exists-by-email/{1}',
    'company/invitation/employee/check/{0}',
    'employee',
    'auth/register/clear',
    'auth/register/employee/complete',
];