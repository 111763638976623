import { PropsWithChildren } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const ReCaptchaProvider = ({children}: PropsWithChildren) => {
    if(process.env.NEXT_PUBLIC_MODE === 'test') {
        return <>{children}</>;
    }
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey="6LfqVColAAAAACLB4p4Zsg7Vtk8oHbI4epfUe1xp"
            language="en"
        >{children}
        </GoogleReCaptchaProvider>
    );
};
