import { API } from '@logic/service/API/baseAPI/API';
import { companyAPI } from '@logic/service/API/companyAPI/companyAPI';
import { ApiErrorAdapter } from '@logic/service/API/error/errorsAdapter';

import autoPaySlice from '@store/reducers/autoPay/autoPaySlice';
import countryCodesSlice from '@store/reducers/codeCountries/codeCountriesSlice';
import freelancerSettingsSlice from '@store/reducers/freelancerSettings/freelancerSettingsSlice';
import generalStatesSlice, { addErrors } from '@store/reducers/generalStates/generalStatesSlice';
import paymentSlice from '@store/reducers/paymentMethod/paymentSlice';
import ratesSlice from '@store/reducers/rates/ratesSlice';
import userSlice from '@store/reducers/user/userSlice';

import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import * as Sentry from '@sentry/nextjs';
import { combineReducers,Middleware, MiddlewareAPI } from 'redux';

// defaults to localStorage for web
import authSlice from './reducers/auth/authSlice';
import chatSlice from './reducers/chat/chatSlice';
import countriesSlice from './reducers/phoneCode/phoneCodeSlice';
import triggerSlice from './reducers/triggers/triggersSlice';
import worksSlice from './reducers/works/worksSlice';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        console.warn('Async error!');
        console.log(action);
        Sentry.captureException(action.payload);
        api.dispatch(addErrors({
            error: ApiErrorAdapter.getPayAssistantError(action.payload),
            title: action?.meta?.baseQueryMeta?.title
        }));
    }
    return next(action);
};

const middlewares = [
    API.middleware,
    rtkQueryErrorLogger
];

if(process.env.NODE_ENV === `development`) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { logger } = require(`redux-logger`);
    middlewares.push(logger);
}

export const rootReducer = combineReducers({
    authSlice,
    countriesSlice,
    worksSlice,
    countryCodesSlice,
    ratesSlice,
    userSlice,
    generalStatesSlice,
    paymentSlice,
    freelancerSettingsSlice,
    [companyAPI.reducerPath]: companyAPI.reducer,
    chatSlice,
    triggerSlice,
    autoPaySlice
});

export const setupStore = () => configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares)
});

export const store = setupStore();

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore[`dispatch`];