import React, {useEffect, useState} from 'react';
import styled, { css } from 'styled-components';

import GeneralPopupError from '@logic/models/GeneralPopupError/GeneralPopupError';

import {useAppDispatch, useAppSelector} from '@store/hooks/hooks';
import {removeError} from '@store/reducers/generalStates/generalStatesSlice';

import { Text } from '@components/common/texts/typography/Typography';
import {Colors} from '@components/styledComponents/base/Colors';
import { Z_INDEX } from '@components/styledComponents/base/vars';
import Portal from "@src/hoc/Portal";
import {Breakpoints} from '@src/styledComponents/base/breakpoints';
import {mediaBreakpointDown} from '@src/styledComponents/base/functions';

import moment from 'moment';

export const ERROR_HEIGHT = 135;
export const ERROR_WIDTH = 330;

export default function GlobalPopupErrors () {


    const {errors, language} = useAppSelector(state => state.generalStatesSlice);

    const dispatch = useAppDispatch();


    const [queue, setQueue] = useState<Array<{
        error: GeneralPopupError,
        verticalPositions: number,
        horizontalPosition: number,
        time: string
    }>>([]);

    useEffect(() => {
        if (errors?.length === 0 || errors?.length <= queue.length || !errors?.at) {
            return;
        }

        setQueue(prev => [
            ...prev.map((el) => ({
                ...el,
                horizontalPosition: 15,
                time: '.5s',
                verticalPositions: el.verticalPositions + ERROR_HEIGHT + 15
            })),
            {
                error: errors.at(-1) as GeneralPopupError,
                horizontalPosition: -500,
                verticalPositions: 15,
                time: '.5s'
            }
        ]);

        setTimeout(() => {
            setQueue(prev => prev.map(el => ({...el, horizontalPosition: 15})));
        }, 100);

        setTimeout(() => {
            setQueue(prev => prev.map((el, i) => ({...el, time: '0s'})));
        }, 200);

    }, [errors, queue]);


    const removeTask = (id: number, delPos: number) => {
        dispatch(removeError(id));
        setQueue(prev => prev.map((el, i) => {
            if(i !== delPos) {
                return el;
            }
            return {
                ...el,
                horizontalPosition: -500,
                time: '.5s'
            };
        }));
        setTimeout(()=>{
            setQueue(prev => prev.filter(el => el.error.id !== id).map((el, i) => {
                if(i >= delPos) {
                    return el;
                }
                return {
                    ...el,
                    verticalPositions: (el.verticalPositions - ERROR_HEIGHT - 15) > 15 ? el.verticalPositions - ERROR_HEIGHT - 15 : 15,
                    time:'.5s'
                };
            }));
        }, 200);

        setTimeout(()=>{
            setQueue(prev => prev.map((el, i) => ({...el, time: '0s'})));
        }, 300);
    };

    const getContainerHeight = () => {
        const height = queue.length * (ERROR_HEIGHT + 42);
        if(height >= (ERROR_HEIGHT * 4 + 10 + 10 * 6)) {
            return (ERROR_HEIGHT * 4 + 10 + 10 * 6);
        }
        return height;
    };


    return (
        // @ts-ignore
        <Portal >
            <Container style={{height: + getContainerHeight() + 'px'}}>
                {queue.map((error, i) => (
                    <ErrorPopup
                        hidden={queue.length >= 5 && (queue.length - 5) >= i}
                        key={i}
                        style={{bottom: `${error.verticalPositions}px`, transition: error.time, left: `${error.horizontalPosition}px`}}
                        onClick={()=> {
                            removeTask(error.error.id, i);
                        }}
                    >

                        <Text font='bodyMedium' as='p' color={Colors.DANGER} >{error.error.title[language]}</Text>
                        {error.error.description &&
                        <Text font='bodySmall' as='p' color={Colors.GRAY_600}>{error.error.description[language]}</Text>}

                        <Text font='bodySmall'
                            as='p'
                            color={Colors.GRAY_600}
                            styles={css`text-align: right;`}
                        >{moment().format('HH:mm')}</Text>
                    </ErrorPopup>
                ))}
            </Container>
        </Portal>
    );
}

//
const Container = styled.div`
  position: fixed;
  z-index: ${Z_INDEX.popupErrors};
  left: 0;
  bottom: 0;
  //width: auto;
  overflow: hidden;
  width: 350px;
  max-height: ${ERROR_HEIGHT * 4 + 10 + 10 * 6}px;

  transition: .3s;
`;


const ErrorPopup = styled.div<{hidden: boolean}>`
  position: absolute;
  
  display: grid;
  grid-template-rows: 17px 1fr 20px;
  grid-gap: 10px;
  border-radius: 14px;
  box-shadow: 0px 4px 34px ${({hidden}) => hidden ? 'transparent' : 'rgb(0 0 0 / 15%)'};
  padding: 10px;
  
  bottom: 0;

  height: ${ERROR_HEIGHT}px;
  width: ${ERROR_WIDTH}px;
    
  ${mediaBreakpointDown(Breakpoints.xMedium)} {
    height: ${ERROR_HEIGHT - 10}px;
    width: ${ERROR_WIDTH - 30}px;
  }

  background: ${({hidden}) => hidden ? 'transparent' : '#ffffff'};

  cursor: pointer;
  
`;

const CloseContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`;