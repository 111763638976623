import {createSlice, PayloadAction} from '@reduxjs/toolkit';


export enum Triggers {
    triggerCompany = 'triggerCompany',
    triggerTasks = 'triggerTasks',
    triggerBalance = 'triggerBalance',
    triggerFinance = 'triggerFinance',
    triggerDocument = 'triggerDocument',
    triggerFreelancers = 'triggerFreelancers',
    triggerPaymentSettings = 'triggerPaymentSettings',
    //Freelancer:
    freelTriggerFreelancer = 'freelTriggerFreelancer',
    freelTriggerTypes = 'freelTriggerTypes',
    freelTriggerPayments = 'freelTriggerPayments',
    freelTriggerTasks = 'freelTriggerTasks',
    freelTriggerBalance = 'freelTriggerBalance',
    freelAutoPayTrigger = 'freelAutoPayTrigger',
    freelMethodsTrigger = 'freelMethodsTrigger',
    freelSettingsTrigger = 'freelSettingsTrigger'
}

const initialState: {[key in Triggers]?: any} = {
    triggerCompany: true,
    triggerTasks: true,
    triggerBalance: true,
    triggerFinance: true,
    triggerDocument: true,
    triggerFreelancers: true,

    //Freelancer:
    freelTriggerFreelancer: true,
    freelTriggerTypes: true,
    freelTriggerPayments: true,
    freelTriggerTasks: true,
    freelTriggerBalance: true,
    freelAutoPayTrigger: true,
    freelSettingsTrigger: true
};

export const triggerSlice = createSlice({
    name: `rates`,
    initialState,
    reducers: {
        turnTasks: (state) => {
            state.triggerTasks = !state.triggerTasks;
        } ,

        turnPaymentSettings: (state) => {
            state.freelAutoPayTrigger = !state.freelAutoPayTrigger;
        },
        turnBalance: (state) => {
            state.triggerBalance = !state.triggerBalance;
        },
        turnGroup: (state, action: PayloadAction<Array<Triggers>>) => {
            for (let i = 0; i < action.payload.length; i++) {
                state[action.payload[i]] = !state[action.payload[i]];
            }
        },
        turnAll: (state) => {
            for (const item in Triggers) {
                state[item as Triggers] = !state[item as Triggers];
            }

        }
    },

});

export const {turnTasks, turnBalance, turnGroup, turnAll, turnPaymentSettings} = triggerSlice.actions;
export default triggerSlice.reducer;
