// export const breakpoints = {
//     'S': 320,
//     'MS': 540,
//     'M': 744,
//     'T': 768,
//     'L': 1024,
//     'D': 1280,
//     'XL': 1440,
//     'FHD': 1920
// };

// export type Breakpoints = keyof typeof breakpoints;

export enum Breakpoints {
    //OLD BREAKPOINTS: 
    'MS' = 540,
    'M' = 744,

    //NEW BREAKPOINTS: 
    'sMobile' = 320,
    'xMobile' = 600,
    'Tablet' = 768,
    'xTablet' = 960,
    'Medium' = 1024,
    'xMedium' = 1280,
    'Large' = 1440,
    'xLarge' = 1600,
    'FHD' = 1920
}