import styled from 'styled-components';

export const RoutLoading = styled.div<{load: boolean}>`
 z-index: 15;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ load }) => load ? 'rgba(0, 0, 0, 0.75);' : 'transparent'};
  visibility: ${({load}) => load ? 'visible' : 'hidden'};
  opacity: ${({ load }) => load ? 1 : 0};
  transition: background-color .5s, visibility .5s, opacity .5s;
`;
